import React from 'react';
import {
    BaseSingleInputFieldProps,
    DatePicker,
    DatePickerProps,
    DateValidationError,
    FieldSection, useClearableField,
    UseDateFieldProps
} from '@mui/x-date-pickers';
import { useForkRef } from '@mui/material/utils';
import momentPath from 'moment/moment';
import {useDateField} from '@mui/x-date-pickers/DateField/useDateField';
import Box from '@mui/material/Box';

import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';

// this code is copied from mui docs

type Moment = momentPath.Moment;

interface FieldProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
    label?: React.ReactNode;
    inputRef?: React.Ref<any>;
    InputProps?: {
        ref?: React.Ref<any>;
        endAdornment?: React.ReactNode;
        startAdornment?: React.ReactNode;
    };
    error?: boolean;
    focused?: boolean;
    ownerState?: any;
    sx?: any;
    enableAccessibleFieldDOMStructure: boolean;
}


const Field = React.forwardRef(
    (props: FieldProps, ref: React.Ref<unknown>) => {
        const {
            // Should be ignored
            enableAccessibleFieldDOMStructure,

            disabled,
            id,
            label,
            inputRef,
            InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
            // extracting `error`, 'focused', and `ownerState` as `input` does not support those props
            error,
            focused,
            ownerState,
            sx,
            ...other
        } = props;

        const handleRef = useForkRef(containerRef, ref);

        const internalSx = {...sx,
            display: 'flex',
            alignItems: 'center',
            '& .MuiInputAdornment-root': {
                height: 'auto',
            },
            'input': {
                borderRadius: 0,
                border: 'none',
                boxShadow: 'none',
                margin: 0,
                padding: 0,
                height: 'auto',
                background: 'none',
                font: 'inherit',

                '::selection': {
                    background: '#565555',
                }
            }};

        return(
            <Box sx={internalSx}
                id={id}
                ref={handleRef}
            >
                <ArrowRightRoundedIcon height={12} width={12} /> <input disabled={disabled} ref={inputRef} {...other} />
            </Box>
        )
    }
)

Field.displayName = 'Field';

interface DateFieldProps
    extends UseDateFieldProps<Moment, false>,
        BaseSingleInputFieldProps<
            Moment | null,
            Moment,
            FieldSection,
            false,
            DateValidationError
        > {}

const DateField = React.forwardRef(
    (props: DateFieldProps, ref: React.Ref<HTMLDivElement>) => {
        const { slots, slotProps, ...textFieldProps } = props;

        const fieldResponse = useDateField<Moment, false, typeof textFieldProps>({
            ...textFieldProps,
            enableAccessibleFieldDOMStructure: false,
        });

        /* If you don't need a clear button, you can skip the use of this hook */
        const processedFieldProps = useClearableField({
            ...fieldResponse,
            slots,
            slotProps,
        });

        return <Field ref={ref} {...processedFieldProps} />;
    },
);

DateField.displayName = 'DateField';

const GridDatePicker = React.forwardRef(
    (props: DatePickerProps<Moment, false>, ref: React.Ref<HTMLDivElement>) => {
        return (
            <DatePicker<Moment, false>
                ref={ref}
                {...props}
                slots={{ ...props.slots, field: DateField }}
            />
        );
    },
);

GridDatePicker.displayName = 'GridDatePicker';

export default GridDatePicker;