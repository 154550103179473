import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import notificationReducer from '../features/snacks/snacksSlice'
import authReducer from '../features/auth/authSplice'
import profileReducer from '../features/profile'
import typeReducer from '../features/types'
import roleReducer from '../features/roles'
import clientReducer from '../features/clients'
import employeesReducer from '../features/employees'
import projectReducer from '../features/projects'
import loaderReducer from '../features/loader'
import modalsReducer from '../features/modals'
import eRoleReducer from '../features/roles/filter'
import eProjectReducer from '../features/projects/filter'
import rEmployeesReducer from '../features/employees/short'
import permissionsReducer from '../features/permissions'
import preferencesReducer from '../features/settings/preferences'
import notificationsReducer from '../features/settings/notifications'
import integrationsReducer from '../features/settings/integrations'
import earlyAccessReducer from '../features/settings/early-access'
import pClientReducer from '../features/clients/filter'
import workcalendar from '../features/settings/workcalendar'
import paymentsReducer from '../features/payments'
import subscriptionReducer from '../features/billing/subscription'
import employeeReducer from '../features/employees/single';
import integrationZohoReducer from '../features/integrations/zoho';
import absencesReducer from '../features/absences';
import shiftsReducer from '../features/shifts';
import { timeSheetReducer } from '../features/schedule';
import indicatorReducer from '../features/indicator';

export const store = configureStore({
    reducer: {
        snacks: notificationReducer,
        auth: authReducer,
        profile: profileReducer,
        subscription: subscriptionReducer,
        types: typeReducer,
        roles: roleReducer,
        employees: employeesReducer,
        clients: clientReducer,
        projects: projectReducer,
        loader: loaderReducer,
        modals: modalsReducer,
        eRoles: eRoleReducer,
        eProjects: eProjectReducer,
        rEmployees: rEmployeesReducer,
        permissions: permissionsReducer,
        preferences: preferencesReducer,
        notifications: notificationsReducer,
        integrations: integrationsReducer,
        earlyAccess: earlyAccessReducer,
        workCalendar: workcalendar,
        pClients: pClientReducer,
        payments: paymentsReducer,
        employee: employeeReducer,
        integrationZoho: integrationZohoReducer,
        absences: absencesReducer,
        shifts: shiftsReducer,
        timesheet: timeSheetReducer,
        indicator: indicatorReducer
    },
    //here you can add additional middlewares with concat function
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['report/download/by-employee/fulfilled',
                    'report/download/by-project/fulfilled',
                    'report/download/detailed/fulfilled'
                ]
            }
        }),
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
