import React, {useEffect, useState} from 'react';
import SimplePageWrapper from '../../components/shared/simple-page-wrapper';
import {
    CreateShift,
    TimeSheetItem,
    TimeSheetItemType,
    TimeSheetItemUpdateState
} from '../../features/schedule/types';
import TimeSheetGrid from './components/timesheet-grid';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {getAllEmployees} from '../../features/employees/short';
import {getProjects} from '../../features/projects/filter';
import {getAbsencesTypes, getShiftsTypes} from '../../features/types';
import moment from 'moment';
import {getWorkCalendar, getWorkCalendars} from '../../features/settings/workcalendar';
import {useIntl} from 'react-intl';
import {
    convertTimeshiftUpdateStateToAbsence,
    convertTimeshiftUpdateStateToShift,
    isAbsenceDirty,
    isCreateShift,
    isEditAbsence,
    isEditShift,
    isShiftDirty,
    toAbsence,
    toShift
} from './functions';
import {getShift, updateShift} from '../../features/shifts';
import {getAbsence, updateAbsence} from '../../features/absences';
import CreateShiftModal from '../../components/Schedule/modals/create-modal';
import {REmployees} from '../../features/roles/types';
import {store} from '../../store/store';
import {clearSelected, selectIdOnDelete, selectIdOnEdit} from '../../features/modals';
import {Shift} from '../../features/shifts/types';
import EditShiftModal from '../../components/Schedule/modals/edit-modal';
import {Employee} from '../../features/employees/types';
import {getEmployee} from '../../features/employees';
import {getProject} from '../../features/projects';
import Index from '../../components/Schedule/modals/delete-shift-modal';
import {Absence} from '../../features/absences/types';
import DeleteAbsenceModal from '../../components/Schedule/modals/delete-absence-modal';
import {TopBar} from './components/top-bar';

import _ from 'lodash';
import useTimeSheetView from './hooks/useTimeSheetView';
import TimeSheetGroupByProvider from './contexts/group-by';
import TimeSheetSearchProvider from './contexts/search';
import {setIndicatorLoading} from '../../features/indicator';

export default function SchedulePage() {
    const today = moment();
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const [isAnyRowExpand, setIsAnyRowExpand] = useState(false);
    const [forceSwitchExpand, setForceSwitchExpand] = useState(false);

    // selector
    const employees = useAppSelector((selector) => selector.rEmployees);
    const projects = useAppSelector((selector) => selector.eProjects);
    const shiftType = useAppSelector((selector) => selector.types.shifts);
    const absenceType = useAppSelector((selector) => selector.types.absences);
    const shifts = useAppSelector((selector) => selector.shifts);
    const absences = useAppSelector((selector) => selector.absences);
    const { workCalendars, year } = useAppSelector((selector) => selector.workCalendar);
    const selectEdit = useAppSelector((store) => store.modals.selectedEdit)
    const { data, loading: dataLoading } = useTimeSheetView();
    const selectDelete = useAppSelector((store) => store.modals.selectedDelete);
    const loading = employees.length < 1 || projects.length < 1 || shiftType.total < 1 || absenceType.total < 1 || workCalendars.length < 1 || !year;

    const bulkUpdateTimeshiftStatus = async (states: Array<TimeSheetItemUpdateState>) => {
        const shiftsToUpdate = states.filter((x) => x.timesheetItemType === TimeSheetItemType.SHIFT).map((s) => convertTimeshiftUpdateStateToShift(s, shifts.items));
        const absencesToUpdate = states.filter((x) => x.timesheetItemType === TimeSheetItemType.ABSENCE).map((s) => convertTimeshiftUpdateStateToAbsence(s, absences.items));
        const promises: Promise<void>[] = [];

        shiftsToUpdate.forEach((us) => {
            promises.push(dispatch(updateShift(us)).unwrap().then())
        })

        absencesToUpdate.forEach((ab) => {
            promises.push(dispatch(updateAbsence(ab)).unwrap().then())
        })

        Promise.all(promises);
    }

    const isShiftNeedToUpdate = (item: TimeSheetItem): boolean => {
        const storedShift = shifts.items.find((s) => s.id == item.itemId);
        if (!storedShift) {
            return true;
        }

        return isShiftDirty(item, storedShift);
    }

    const isAbsenceNeedToUpdate = (item: TimeSheetItem): boolean => {
        const storedAbsence = absences.items.find((s) => s.id == item.itemId);
        if (!storedAbsence) {
            return true;
        }

        return isAbsenceDirty(item, storedAbsence);
    }


    const handleTimeshiftUpdate = (timeSheetItem: TimeSheetItem) => {
        if (timeSheetItem.timeshiftType === TimeSheetItemType.SHIFT && isShiftNeedToUpdate(timeSheetItem)) {
            setIndicatorLoading(true);
            dispatch(getProject(timeSheetItem.targetId)).unwrap().then((r) => {
                dispatch(updateShift(toShift(timeSheetItem, shifts.items, shiftType.items, r)))
            }).finally(() => setIndicatorLoading(false));
        } else if (timeSheetItem.timeshiftType === TimeSheetItemType.ABSENCE && isAbsenceNeedToUpdate(timeSheetItem)) {
            setIndicatorLoading(true);
            dispatch(updateAbsence(toAbsence(timeSheetItem, absences.items, absenceType.items))).finally(() => setIndicatorLoading(false));
        }
    };

    const handleAddNewItemClick = async (targetTimeshift: TimeSheetItem) => {
        let employeeId: number | undefined = 0;
        if (targetTimeshift.timeshiftType === TimeSheetItemType.SHIFT) {
            employeeId = shifts.items.find((x) => x.id === targetTimeshift.itemId)?.employeeId
        } else {
            employeeId = absences.items.find((x) => x.id === targetTimeshift.itemId)?.employeeId
        }
        const foundEmployee = employees.find((e) => e.id === employeeId);

        beginCreateNewShift(foundEmployee, targetTimeshift.date);
    };

    const handleEditItemClick = async (timeshift: TimeSheetItem) => {
        if (timeshift.timeshiftType === TimeSheetItemType.SHIFT) {
            await dispatch(getShift(timeshift.itemId)).unwrap().then((shift) => {
                if (!shift) {
                    return;
                }

                store.dispatch(selectIdOnEdit(shift))
            })

            return;
        }

        await dispatch(getAbsence(timeshift.itemId)).unwrap().then((absence) => {
            if (!absence) {
                return;
            }

            store.dispatch(selectIdOnEdit(absence))
        })
    }

    const getShiftEmployee = async (timeshift: TimeSheetItem): Promise<Employee> => {
        const employeeId = shifts.items.find((x) => x.id === timeshift.itemId)!.employeeId
        return await dispatch(getEmployee(employeeId)).unwrap()
    }

    const beginCreateNewShift = (employeeId?: REmployees, date?: string) => {
        const createShift: CreateShift = {
            employees: employeeId ? [employeeId] : employees,
        }
        if (date) {
            createShift.state = {
                date: date
            }
        }
        store.dispatch(selectIdOnEdit(createShift));
    };

    const beginDeleteItem = (item: TimeSheetItem) => {
        if (item.timeshiftType === TimeSheetItemType.SHIFT) {
            const shift = shifts.items.find((x) => x.id === item.itemId);
            if (!shift) {
                return;
            }

            store.dispatch(selectIdOnDelete(shift))

            return;
        }

        const absence = absences.items.find((x) => x.id === item.itemId);
        if (!absence) {
            return;
        }

        store.dispatch(selectIdOnDelete(absence))
    }

    const handleShiftEditingComplete = () => {
        store.dispatch(clearSelected())
    };

    const handleSetForceSwitch = () => {
        setForceSwitchExpand((s) => !s);
    }

    useEffect(() => {
        setIndicatorLoading(dataLoading);
    }, [dataLoading]);

    useEffect(() => {
        dispatch(getAllEmployees());
        dispatch(getProjects());
        dispatch(getShiftsTypes(0));
        dispatch(getAbsencesTypes(0));
        dispatch(getWorkCalendars()).unwrap().then((value) => {
            const primaryCalendar = value[0]!;
            const year = parseInt(today.format('yyyy'));
            dispatch(getWorkCalendar({ workCalendarId: primaryCalendar.id, year: year }))
        });
    }, [])

    return <SimplePageWrapper>
        <TimeSheetGroupByProvider>
            <TimeSheetSearchProvider>
                {/* HEADER */}
                {
                    <TopBar loading={loading} year={year} today={today} onCreateTimeSheetItemClick={() => beginCreateNewShift()}
                        isItemsExpand={isAnyRowExpand} onCollapseClick={handleSetForceSwitch} items={data} />
                }
                {/* BODY */}
                {<>
                    {<TimeSheetGrid data={data}
                        shiftTypes={shiftType}
                        absenceTypes={absenceType}
                        intl={intl}
                        onStateChange={bulkUpdateTimeshiftStatus}
                        projects={projects}
                        onTimeshiftUpdate={handleTimeshiftUpdate}
                        onAddNewShiftClick={handleAddNewItemClick}
                        forceSwitch={forceSwitchExpand}
                        notifySwitch={setIsAnyRowExpand}
                        onEditItemClick={async (timeshift) => await handleEditItemClick(timeshift)}
                        getShiftEmployee={async (timeshift) => await getShiftEmployee(timeshift)}
                        onDeleteItemClick={beginDeleteItem}/>}
                </>
                }
            </TimeSheetSearchProvider>
        </TimeSheetGroupByProvider>
        {/* MODALS */}
        {selectEdit && isCreateShift(selectEdit) && <CreateShiftModal create={selectEdit as CreateShift} onCreate={handleShiftEditingComplete} shiftTypes={_.orderBy(shiftType.items, (i) => i.id)}/>}
        {selectEdit && isEditShift(selectEdit) && <EditShiftModal edit={selectEdit as Shift} shiftTypes={_.orderBy(shiftType.items, (i) => i.id)} onComplete={handleShiftEditingComplete} />}
        {/* template code for next ability, will be done soon {selectEdit && isEditAbsence(selectEdit) && <EditModal edit={selectEdit as Absence} shiftTypes={_.orderBy(shiftType.items, (i) => i.id)} onComplete={handleShiftEditingComplete} />}*/}
        {selectDelete && isEditShift(selectDelete) && <Index shift={selectDelete as Shift}/>}
        {selectDelete && isEditAbsence(selectDelete) && <DeleteAbsenceModal absence={selectDelete as Absence}/>}
    </SimplePageWrapper>
}