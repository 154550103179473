import React, {ChangeEvent, useEffect, useRef} from 'react';
import styles from './edit-cell.module.scss';

const TextEditCell = (props: { value: string, onChange: (value: any) => void, active: boolean, placeholder?: any }) => {
    const { active } = props;
    const ref = useRef<HTMLTextAreaElement>(null);
    const [value, setValue] = React.useState(props.value);

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
        props.onChange(e.target.value);
    }

    useEffect(() => {
        if (!active) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref.current.blur();
        } else if (value) {
            ref.current?.setSelectionRange(value.length, value.length);
        }
    }, [active]);
    return (<textarea autoFocus={true} ref={ref} placeholder={props.placeholder} className={styles.text} value={value} onChange={handleChange} />)
}

export default TextEditCell;