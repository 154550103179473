import React, {useEffect} from 'react'
import {Shift} from '../../../../features/shifts/types';
import {useAppDispatch} from '../../../../hooks/hooks';
import useModal from '../../../../hooks/useModal';
import {deleteShift} from '../../../../features/shifts';
import DeleteModal from '../../../shared/modal/delete-modal';
import MediaQuery from 'react-responsive';
import IconTextLarge from '../../../shared/large-icon-text';
import {SVGS} from '../../../../assets/images';
import {useIntl} from 'react-intl';
import { Lx } from '../../../../i18n/consts';

interface Props {
    shift: Shift
}

export default function Index(props: Props) {
    const dispatch = useAppDispatch();
    const {open, onToggle, openModal} = useModal();
    const intl = useIntl();

    useEffect(() => {
        openModal();
    }, [])

    const save = () => {
        return dispatch(deleteShift(props.shift.id)).unwrap().then(() => {
            onToggle();
        })
    }

    return <>
        {
            /*TODO localization*/
            open && <DeleteModal open={open} title='shift' onClose={onToggle} onConfirm={save}>
                <MediaQuery maxWidth={1000}>
                    <IconTextLarge text={intl.formatMessage({ id: Lx.Schedule.DELETE_TIMESHIFT_SHIFT })}
                        subtext={`Are you sure that you want to delete "${props.shift.id}" shift?`}
                        icon={<SVGS.DeleteIcon />} />
                </MediaQuery>
                <MediaQuery minWidth={1000}> {/*TODO localization*/}
                    Are you sure that you want to delete &quot;{props.shift.id}&quot; shift?
                </MediaQuery>
            </DeleteModal>
        }
    </>
}