import React from 'react';
import {GridColumnType, GridIdNameModel} from '../../types';
import DropdownEditCell from './dropdown-cell';
import TextEditCell from './textedit-cell';
import DateEditCell from './dateedit-cell';

interface EditCellProps {
    type: GridColumnType;
    value: any;
    onChange: (value: any) => void;
    active: boolean;
    options?: GridIdNameModel[];
    getOptions?: () => Promise<GridIdNameModel[]>;
    placeholder?: any;
    lazyLoad?: boolean;
}

const EditCell = (props: EditCellProps) => {
    const { type, value, options, getOptions, active, onChange, lazyLoad } = props;

    switch (type) {
        case 'text':
            return (<TextEditCell value={value} onChange={onChange} active={active} placeholder={props.placeholder} />);
        case 'singleSelect':
            return lazyLoad && getOptions ? (<DropdownEditCell value={value} options={[]} getOptions={getOptions} onChange={onChange} active={active} lazyLoad placeholder={props.placeholder} />)
                : (<DropdownEditCell value={value} options={options ?? []} onChange={onChange} active={active} placeholder={props.placeholder} />)
        case 'date':
            return (<DateEditCell value={value} onChange={onChange} active={active} />)
        default:
            return (<></>)
    }
}

export default EditCell