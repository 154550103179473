import {AxiosInstance, AxiosResponse} from 'axios';

export interface IShiftsApi {
    getMany<T>(dateFrom: string, dateTo: string, employees: Array<number>, projects: Array<number>, types: Array<number>): Promise<AxiosResponse<T>>;
    get<T>(id: number): Promise<AxiosResponse<T>>;
    create<T>(body: T): Promise<AxiosResponse<T>>;
    update<T>(id: number, body: T): Promise<AxiosResponse<T>>;
    updateApproved<T>(id: number, value: boolean): Promise<AxiosResponse<T>>;
    updateBillable<T>(id: number, value: boolean): Promise<AxiosResponse<T>>;
    delete(id: number): Promise<AxiosResponse>;
}

export default class ShiftsApi implements IShiftsApi {
    private readonly _axiosInstance: AxiosInstance;
    private readonly _controller: string = 'shifts';
    private readonly _headers = { headers: { 'Content-Type': 'application/json' } };

    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance;
    }

    async getMany<T>(dateFrom: string, dateTo: string, employees: number[], projects: number[], types: number[]): Promise<AxiosResponse<T>> {
        const employeesSearch = employees.map((x) => `&employees=${x}`).join('');
        const projectsSearch = projects.map((x) => `&projects=${x}`).join('');
        const typesSearch = types.map((x) => `&shiftTypes=${x}`).join('');
        return await this._axiosInstance.get<T>(`${this._controller}?from=${dateFrom}&to=${dateTo}${employeesSearch}${projectsSearch}${typesSearch}`);
    }
    async get<T>(id: number): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this._controller}/${id}`);
    }
    async create<T>(body: T): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.post<T>(`${this._controller}`, JSON.stringify(body), this._headers);
    }
    async update<T>(id: number, body: T): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.put<T>(`${this._controller}/${id}`, JSON.stringify(body), this._headers);
    }
    async updateApproved<T>(id: number, value: boolean): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.put<T>(`${this._controller}/${id}/approved`, JSON.stringify(value), this._headers);
    }
    async updateBillable<T>(id: number, value: boolean): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.put<T>(`${this._controller}/${id}/billable`, JSON.stringify(value), this._headers);
    }
    async delete(id: number): Promise<AxiosResponse> {
        return await this._axiosInstance.delete(`${this._controller}/${id}`);
    }
}