import React from 'react';

const notImplemented = () => {
    throw new Error('method not implemented');
}

export interface TimeSheetSearchContext {
    value: string;
    change: (value: string) => void;
}

export const initialState: TimeSheetSearchContext = {
    value: '',
    change: notImplemented,
}

export const TimeSheetSearchContext = React.createContext<TimeSheetSearchContext>(initialState);