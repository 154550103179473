import React from 'react';
import styles from './total.module.scss';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {Lx} from '../../../i18n/consts';
import ProgressIndicator from '../progress-indicator';

interface Props {
    workHours?: number;
    absenceHours?: number;
    approved: number;
    billed: number;
    approvable?: number;
    billable?: number;
    totalHours?: number;
    hideTrackingHours?: boolean;
    hideBillable?: boolean;
}

const TotalV2: React.FC<Props> = (props) => {
    const { workHours, absenceHours, approved, billed, approvable, billable, totalHours, hideTrackingHours, hideBillable } = props;
    const calculatedApproved = totalHours && (approved / totalHours) * 100;
    const calculatedBilled = workHours && (billed / workHours) * 100;


    return (<div className={classNames({
        [styles.total]: true,
        [styles.twoCol]: hideTrackingHours,
    })}>
        {
            !hideTrackingHours && <>
                {
                    workHours !== undefined && <div className={styles.totalPart}>
                        <p><FormattedMessage id={Lx.Schedule.WORKED_HOURS_ABBREVIATION} />:
                        </p>{Math.floor(workHours)}
                    </div>
                }
                {
                    absenceHours !== undefined && <div className={styles.totalPart}>
                        <p><FormattedMessage id={Lx.Schedule.ABSENCE_HOURS_ABBREVIATION} />:
                        </p>{Math.floor(absenceHours)}
                    </div>
                }
            </>
        }
        <div className={classNames({
            [styles.totalPart]: true,
            [styles.withIndicator]: true,
            [styles.inactive]: approved < 1,
        })}>
            <p><FormattedMessage id={Lx.Schedule.APPROVED_HOURS}/>:</p><span
                className={styles.totalApproved}>{Math.floor(approved)}</span>
            {<span className={classNames({
                [styles.indicator]: true,
                [styles.active]: approvable && approvable >= 0 && approved > 0
            })}>
                <ProgressIndicator type={'approve'} value={calculatedApproved ? calculatedApproved : 0}/>
            </span>}
        </div>
        <div className={classNames({
            [styles.totalPart]: true,
            [styles.withIndicator]: true,
            [styles.inactive]: billed < 1,
            [styles.hide]: hideBillable
        })}><p><FormattedMessage id={Lx.Schedule.BILLABLE_HOURS}/>:</p><span
                className={styles.totalBillable}>{Math.floor(billed)}</span>
            {!!calculatedBilled &&
                <span className={classNames({
                    [styles.indicator]: true,
                    [styles.active]: billable && billable < 1 && billed > 0
                })}>
                    <ProgressIndicator type={'billable'} value={calculatedBilled}/>
                </span>}
        </div>
    </div>)
}

export default TotalV2;
