import {GridColumnType, GridFlexProps} from '../../types';
import classNames from 'classnames';
import styles from '../../grid.module.scss';
import React, {HTMLAttributes} from 'react';
import moment from 'moment';
import {SVGS} from '../../../../../assets/images';
import { ClickAwayListener } from '@mui/material';

const formatDateCell = (value: string): string => {
    return moment(value).format('L')
}

const FormatCell = (props: { value: string, hover: boolean, editable?: boolean, type?: GridColumnType, placeholder?: string }): React.ReactElement => {
    const { value, hover, type, editable } = props;
    if (!type) {
        return (<>{value}</>)
    }

    switch (type) {
        case 'date':
            return (<>{formatDateCell(value)}</>)
        case 'singleSelect':
            return (<span className={classNames({
                [styles.dropdownCell]: true,
                [styles.editable]: editable
            })}><span>{value}</span><SVGS.DropdownIcon /></span>)
        case 'text':
            return (<span className={styles.cellText}>
                {value}
                <span className={classNames({
                    [styles.placeholder]: props.placeholder !== undefined,
                    [styles.active]: !value,
                    [styles.hover]: hover
                })}>{props.placeholder}</span>
            </span>)
        default:
            return (<span className={styles.value}>{value}</span>);
    }
}

interface Props {
    flex: boolean | GridFlexProps;
    muted: boolean;
    editable: boolean;
    type: GridColumnType;
    children: any;
    active: boolean;
    onOutsideClick: () => void;
    cellProps: React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
    placeholder?: string;
}

const Cell = (props: Props) => {
    const { active, children, onOutsideClick, cellProps } = props;
    const [hover, setHover] = React.useState(false);
    const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
        setHover(true)
        cellProps.onMouseEnter?.(event)
    };

    const handleMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
        setHover(false)
        cellProps.onMouseLeave?.(event)
    };

    return(<ClickAwayListener onClickAway={() => onOutsideClick()}>
        <div className={classNames({
            [styles.cell]: true,
            [styles.muted]: props.muted,
            [styles.editable]: props.editable,
            [styles.text]: props.type == 'string' || props.type === 'text' || props.type === 'date',
            [styles.active]: props.active
        })}
        {...cellProps}
        onMouseEnter={(event) => handleMouseEnter(event)}
        onMouseLeave={(event) => handleMouseLeave(event)}>
            {
                active ? children :
                    <FormatCell value={children} type={props.type} editable={props.editable}
                        hover={hover} placeholder={props.placeholder} />
            }
        </div>
    </ClickAwayListener>)
}

export default Cell;