import { ReactComponent as RolesIcon } from './svgs/roles-icon.svg';
import { ReactComponent as ClientsIcon } from './svgs/clients-icon.svg';
import { ReactComponent as TypesIcon } from './svgs/types-icon.svg'
import { ReactComponent as EmployeesIcon } from './svgs/employees-icon.svg'
import { ReactComponent as ProjectsIcon } from './svgs/projects-icon.svg'
import { ReactComponent as SettingsIcon } from './svgs/settings-icon.svg'
import { ReactComponent as LogoIcon } from './svgs/logo.svg'
import { ReactComponent as ActionIcon } from './svgs/action-icon.svg'
import { ReactComponent as EditIcon } from './svgs/edit-icon.svg'
import { ReactComponent as DeleteIcon } from './svgs/delete-icon.svg'
import { ReactComponent as PlusIcon } from './svgs/plus-icon.svg'
import { ReactComponent as LogoMobileIcon } from './svgs/logo-mobile.svg'
import { ReactComponent as MenuMobileIcon } from './svgs/menu-mobile-icon.svg'
import { ReactComponent as ProfileIcon } from './svgs/profile-icon.svg'
import { ReactComponent as ErrorIcon } from './svgs/error-icon.svg'
import { ReactComponent as LeftChevronIcon } from './svgs/leftchevron-icon.svg';
import { ReactComponent as RightChevronIcon } from './svgs/rightchevron-icon.svg';
import { ReactComponent as CreditCardIcon } from './svgs/creditcard-icon.svg'
import { ReactComponent as LogOutIcon } from './svgs/logout-icon.svg'
import { ReactComponent as LogoSkeletonIcon } from './svgs/logo-skeleton.svg';
import { ReactComponent as AccountIcon } from './svgs/account-icon.svg';
import { ReactComponent as StripeIcon } from './svgs/stripe.svg';
import { ReactComponent as ActionVertIcon } from './svgs/action-vert-icon.svg';
import { ReactComponent as InfoIcon } from './svgs/info-icon.svg';
import { ReactComponent as CalendarIcon } from './svgs/calendar-icon.svg';
import { ReactComponent as CancelIcon } from './svgs/cancel-icon.svg';
import { ReactComponent as LogoJIcon } from './svgs/logo-icon.svg';
import { ReactComponent as UnfoldIcon } from './svgs/uncollapse-icon.svg';
import { ReactComponent as FoldIcon } from './svgs/collapse-icon.svg';
import { ReactComponent as AddChartIcon } from './svgs/addchart-rounded.svg'
import { ReactComponent as DropdownIcon } from './svgs/arrow-dropdown-icon.svg';
import { ReactComponent as PersonIcon } from './svgs/person-icon.svg';
import { ReactComponent as TasksIcon } from './svgs/tasks-icon.svg';
import { ReactComponent as KeyboardArrowDownIcon } from './svgs/keyboard-arrow-down-icon.svg';
import { ReactComponent as ApproveIcon } from './svgs/approve-icon.svg';
import { ReactComponent as DisapproveIcon } from './svgs/dontdisturb-icon.svg';
import { ReactComponent as MoneyOffIcon } from './svgs/moneyoff-icon.svg';
import { ReactComponent as MoneyIcon } from './svgs/money-icon.svg';
import { ReactComponent as CheckIcon } from './svgs/check-icon.svg';
import { ReactComponent as SearchIcon } from './svgs/search-icon.svg';
import { ReactComponent as FilterIcon } from './svgs/filter-icon.svg';

export const SVGS = {
    RolesIcon,
    ClientsIcon,
    TypesIcon,
    EmployeesIcon,
    ProjectsIcon,
    SettingsIcon,
    LogoIcon,
    ActionIcon,
    EditIcon,
    DeleteIcon,
    PlusIcon,
    LogoMobileIcon,
    MenuMobileIcon,
    ProfileIcon,
    ErrorIcon,
    LeftChevronIcon,
    RightChevronIcon,
    CreditCardIcon,
    LogOutIcon,
    LogoSkeletonIcon,
    AccountIcon,
    StripeIcon,
    ActionVertIcon,
    InfoIcon,
    CalendarIcon,
    CancelIcon,
    LogoJIcon,
    UnfoldIcon,
    FoldIcon,
    AddChartIcon,
    DropdownIcon,
    PersonIcon,
    TasksIcon,
    KeyboardArrowDownIcon,
    ApproveIcon,
    DisapproveIcon,
    MoneyOffIcon,
    MoneyIcon,
    CheckIcon,
    SearchIcon,
    FilterIcon
}
