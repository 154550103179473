import {Backdrop, Box, CircularProgress} from '@mui/material';
import React from 'react';
import styles from './page-wrapper.module.scss';
import {useAppSelector} from '../../../hooks/hooks';
import {StateStatus} from '../../../features/loader/types';

export default function PageWrapper(props: any) {
    const loading = useAppSelector(store => store.loader.status);
    
    return (<>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading === StateStatus.PENDING}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Box className={styles.pageWrapper}>
            <div className={styles.container}>
                {props.children}
            </div>
        </Box></>)
}
