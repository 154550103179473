import React from 'react';
import { useAppSelector } from '../../../../../hooks/hooks';
import Box from '@mui/material/Box';
import {FilterState } from './types';
import PropertyFilterPopup from '../property-filter-popup';

interface Props {
    onChange: (state: FilterState) => Promise<void>;
    disableStatusFilter?: boolean;
}


const Filter = (props: Props) => {
    const employees = useAppSelector((selector) => selector.rEmployees);
    const projects = useAppSelector((selector) => selector.eProjects);
    const shiftTypes = useAppSelector((selector) => selector.types.shifts);
    const absenceTypes = useAppSelector((selector) => selector.types.absences);

    return (<Box display={'flex'} gap={2} flexGrow={1}>
        <PropertyFilterPopup employees={employees}
            projects={projects}
            shiftTypes={shiftTypes.items}
            absenceTypes={absenceTypes.items}
            onChange={props.onChange}
            disableStatusFilter={props.disableStatusFilter}/>
    </Box>)
};

export default React.memo(Filter);