import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
    loading: false,
}

const indicatorSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }
    },
});

export const { setLoading } = indicatorSlice.actions;

export default indicatorSlice.reducer;