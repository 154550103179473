import { Box, Button, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { TimeSheetItemStatus, TimeSheetItemType } from '../../../features/schedule/types';
import styles from './time-shift.module.scss'
import classNames from 'classnames';
import StateIcon from '../timesheet-state-icon';
import ToolActionMenu from './components/action-menu';
import { Lx } from '../../../i18n/consts';

interface Props {
    intl: IntlShape;
    totalHours: number;
    approved: number;
    billed: number;
    approvable: number;
    billable: number;
    onAddNewShiftClick?: () => Promise<void>;
    onCancelBillClick?: () => Promise<void>;
    onBillClick?: () => Promise<void>;
    onDisaproveClick?: () => Promise<void>;
    onApproveClick?: () => Promise<void>;
    totalWorkHours?: number;
    hideButtons?: boolean;
}

const ToolBox = ( props: { children: React.ReactNode, small: boolean } ) => {
    const className = props.small ? `${styles.container} ${styles.small}` : styles.container;
    return(<Box className={className}>
        {props.children}
    </Box>)
}

const getCurrentStateFromHours = (total: number, approved: number, billed: number, totalWorkHours?: number): TimeSheetItemStatus => {
    if (total < 1) {
        return TimeSheetItemStatus.NORMAL;
    }
    if (totalWorkHours && billed === totalWorkHours){
        return TimeSheetItemStatus.BILLABLE;
    }

    if (approved === total) {
        return TimeSheetItemStatus.APPROVED;
    }

    return TimeSheetItemStatus.NORMAL;
}

const TimeSheetGroupTool: React.FC<Props> = (props) => {
    const { totalWorkHours, intl, approved, approvable, billed, billable } = props;
    const [state, setState] = useState<TimeSheetItemStatus>(getCurrentStateFromHours(props.totalHours, approved, billed, totalWorkHours));
    const disabledBillable = !totalWorkHours || totalWorkHours < 1;
    const approveTooltipTitle = approvable > 0 ? intl.formatMessage({ id: Lx.Schedule.APPROVE_ALL_FORMAT }, { s: approvable }) : intl.formatMessage({ id: Lx.Schedule.DISAPPROVE_FORMAT }, { s: approved });
    const billTooltipTitle = billable > 0 ? intl.formatMessage({ id: Lx.Schedule.BILL_ALL_FORMAT}, { s: billable }) : intl.formatMessage({ id: Lx.Schedule.CANCEL_BILL_FORMAT }, { s: billed });

    const handleApproveButtonClick = () => {
        if (state == TimeSheetItemStatus.BILLABLE || state == TimeSheetItemStatus.APPROVED) {
            Promise.resolve(props.onDisaproveClick?.());
            return;
        }

        Promise.resolve(props.onApproveClick?.());
    }

    const handleBillButtonClick = () => {
        if (state == TimeSheetItemStatus.BILLABLE) {
            Promise.resolve(props.onCancelBillClick?.());
            return;
        }

        Promise.resolve(props.onBillClick?.());
    }

    useEffect(() => {
        setState(getCurrentStateFromHours(props.totalHours, approved, billed, totalWorkHours));
    }, [approved, billed])

    return(<ToolBox small={props.hideButtons !== undefined && props.hideButtons}>
        <div className={classNames({
            [styles.col]: true,
        })}>
            <div className={styles.hours}>{props.totalHours} h</div>
        </div>
        {
            !props.hideButtons && <Box className={classNames({
                [styles.menu]: true,
            })}>
                <Tooltip title={approveTooltipTitle}>
                    <span>
                        <Button className={classNames({
                            [styles.state]: true,
                            [styles.approved]: state != TimeSheetItemStatus.NORMAL,
                        })} onClick={handleApproveButtonClick}>
                            <StateIcon
                                status={state !== TimeSheetItemStatus.NORMAL ? TimeSheetItemStatus.APPROVED : TimeSheetItemStatus.NORMAL}
                                active={state !== TimeSheetItemStatus.NORMAL}/>
                        </Button>
                    </span>
                </Tooltip>
                {
                    !disabledBillable && <Tooltip title={billTooltipTitle}>
                        <span>
                            <Button className={classNames({
                                [styles.state]: true,
                                [styles.billable]: state == TimeSheetItemStatus.BILLABLE
                            })} onClick={handleBillButtonClick}>
                                <StateIcon status={TimeSheetItemStatus.BILLABLE}
                                    active={state === TimeSheetItemStatus.BILLABLE}/>
                            </Button>
                        </span>
                    </Tooltip>
                }
                <ToolActionMenu intl={intl}
                    status={state}
                    approvedHours={approved}
                    approvableHours={approvable}
                    billedHours={billed}
                    billableHours={billable}
                    onAddNewShiftClick={props.onAddNewShiftClick}
                    onApproveClick={props.onApproveClick}
                    onDisaproveClick={props.onDisaproveClick}
                    onBillClick={props.onBillClick}
                    onCancelBillClick={props.onCancelBillClick}
                    disableBillable={disabledBillable}/>
            </Box>
        }
    </ToolBox>)
}

export default TimeSheetGroupTool;