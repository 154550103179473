import {Box, Button, Popover} from '@mui/material';
import React, {useReducer} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import { SVGS } from '../../../../../assets/images';
import { Lx } from '../../../../../i18n/consts';
import Selector from '../../../../shared/nextgen/selector';
import TypesSelector from '../types-selector';
import {TiminatorAbsenceType, TiminatorType} from '../../../../../features/types/types';
import {FilterActionType, FilterChangeAction, FilterState} from '../filter/types';
import {REmployees} from '../../../../../features/roles/types';
import { PIdNameModel } from '../../../../../features/projects/types';
import {initialStatuses} from '../../../../../features/schedule/slice';
import ResetLink from './reset-link';
import classNames from 'classnames';

import styles from './property-filter-popup.module.scss'
import FilterListPreview from './filter-list-preview';

interface Props {
    employees: REmployees[];
    projects: PIdNameModel[];
    shiftTypes: TiminatorType[];
    absenceTypes: TiminatorAbsenceType[];
    onChange: (filter: FilterState) => void;
    disableStatusFilter?: boolean;
}

interface State {
    draft: FilterState;
    current: FilterState;
}

export default function PropertyFilterPopup(props: Props) {
    const intl = useIntl();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [readOnlyAnchorEl, setReadOnlyAnchorEl] = React.useState<HTMLElement | null>(null);
    const [selectedFiltersCount, setSelectedFiltersCount] = React.useState(props.disableStatusFilter ? 0 : 1);

    const emptyFilterState: FilterState = {
        selectedEmployees: [],
        selectedProjects: [],
        selectedShiftTypes: [],
        selectedAbsenceTypes: [],
        selectedStatuses: []
    }

    const initialFilterState: FilterState = {
        ...emptyFilterState,
        selectedStatuses: initialStatuses,
    }

    const initialState: State = {
        current: initialFilterState,
        draft: initialFilterState,
    }

    const handlePropertyChange = (action: FilterActionType, state: FilterState, payload: any): FilterState => {
        switch (action) {
            case 'EMPLOYEES_UPDATE':
                state.selectedEmployees = payload ?? [];
                break;
            case 'PROJECTS_UPDATE':
                state.selectedProjects = payload ?? [];
                break;
            case 'SHIFTTYPE_UPDATE':
                state.selectedShiftTypes = payload ?? [];
                break;
            case 'ABSENCETYPE_UPDATE':
                state.selectedAbsenceTypes = payload ?? [];
                break;
            case 'STATUSES_UPDATE':
                state.selectedStatuses = payload ?? [];
                break;
        }

        return state;
    }

    const reducer = (state: State, action: Partial<FilterChangeAction>): State => {
        const nextState = {...state};
        switch (action.action) {
            case 'APPLY':
                nextState.current = {...state.draft};
                break;
            case 'CANCEL':
                nextState.draft = {...state.current};
                break;
            case 'RESET':
                nextState.draft = {...emptyFilterState};
                break;
            default:
                nextState.draft = handlePropertyChange(action.action as FilterActionType, nextState.draft, action.payload);
                break;
        }

        const currentFilters = Object.keys(nextState.current).filter((x) => !(props.disableStatusFilter && x === 'selectedStatuses')).map((x) => (nextState.current[x as keyof FilterState]));

        setSelectedFiltersCount(Object.values(currentFilters).filter((x) => x.length > 0).length);

        return nextState;
    }

    const [filterState, dispatch] = useReducer(reducer, initialState);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const resetFilter = () => {
        dispatch({ action: 'RESET' })
    }

    const applyFilter = () => {
        dispatch({ action: 'APPLY' });
        props.onChange(filterState.draft);
        setAnchorEl(null)
    }

    const handleClose = () => {
        dispatch({ action: 'CANCEL' });
        setAnchorEl(null);
    };

    const handleReadOnlyPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setReadOnlyAnchorEl(event.currentTarget);
    };

    const handleReadOnlyPopoverClose = () => {
        setReadOnlyAnchorEl(null);
    };

    const readOnlyOpen = Boolean(readOnlyAnchorEl);
    const readOnlyId = readOnlyOpen ? 'filter-view-popover': undefined;

    const open = Boolean(anchorEl);
    const id = open ? 'filter-change-popover' : undefined;

    return (<>
        <Button variant={'scheduleAction'} onClick={handleClick} className={classNames({
            [styles.filterButton]: true,
            [styles.focused]: open,
            [styles.active]: selectedFiltersCount > 0
        })}
        >
            <SVGS.FilterIcon />
            <FormattedMessage id={Lx.General.FILTER} />
            { selectedFiltersCount > 0 && <Box className={styles.filterCount} 
                onMouseEnter={handleReadOnlyPopoverOpen}
                onMouseLeave={handleReadOnlyPopoverClose}>{selectedFiltersCount}</Box>}
        </Button>
        {/* filter view */}
        <Popover id={readOnlyId} open={readOnlyOpen} anchorEl={readOnlyAnchorEl}
            sx={{
                pointerEvents: 'none',
                marginTop: '4px',
                '.MuiPopover-paper': {
                    borderRadius: '8px',
                    maxWidth: '300px',
                    flexDirection: 'column',
                    background: styles.paperBackgroundColor,
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }} 
            hideBackdrop
            onClose={handleReadOnlyPopoverClose}
            disableRestoreFocus>
            <FilterListPreview
                selectedEmployees={filterState.current.selectedEmployees}
                selectedProjects={filterState.current.selectedProjects}
                selectedShiftTypes={filterState.current.selectedShiftTypes}
                selectedAbsenceTypes={filterState.current.selectedAbsenceTypes}
                selectedStatuses={props.disableStatusFilter ? [] : filterState.current.selectedStatuses} />
        </Popover>
        {/* filter change popup */}
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                marginTop: '4px',
                '.MuiPopover-paper': {
                    borderRadius: '8px',
                    minWidth: '300px',
                    maxWidth: '300px',
                    flexDirection: 'column',
                    background: 'transparent',
                }
            }}
        >
            <Box className={styles.filterHeader}>
                <FormattedMessage id={Lx.General.FILTER} />
                <Button onClick={handleClose}><SVGS.CancelIcon /></Button>
            </Box>
            <Box className={styles.filtersWrapper}>
                <Selector id={'employeeFilter'}
                    label={Lx.General.EMPLOYEES}
                    items={props.employees.map((employee, index) => ({ id: index, name: employee.name, originalId: employee.id }))}
                    name={Lx.General.EMPLOYEES}
                    selected={filterState.draft.selectedEmployees}
                    maxWidth={300}
                    setSelectedValues={(values) => dispatch({ action: 'EMPLOYEES_UPDATE', payload: values })} intl={intl}/>

                <Selector id={'projectsFilter'}
                    label={Lx.General.PROJECTS}
                    items={props.projects.map((project, index) => ({ id: index, name: project.name, originalId: project.id }))}
                    name={Lx.General.PROJECTS}
                    selected={filterState.draft.selectedProjects}
                    maxWidth={300}
                    setSelectedValues={(values) => dispatch({ action: 'PROJECTS_UPDATE', payload: values })}
                    intl={intl}/>
                <TypesSelector selectedShiftTypes={filterState.draft.selectedShiftTypes}
                    selectedAbsenceTypes={filterState.draft.selectedAbsenceTypes}
                    shiftTypes={props.shiftTypes}
                    absenceTypes={props.absenceTypes}
                    onShiftTypesSelected={(values) => dispatch({ action: 'SHIFTTYPE_UPDATE', payload: values })}
                    onAbsenceTypesSelected={(values) => dispatch({ action: 'ABSENCETYPE_UPDATE', payload: values })}
                    intl={intl}/>
                {
                    !props.disableStatusFilter && <Selector id={'statusFilter'}
                        label={Lx.General.STATUS}
                        items={initialStatuses.map((x) => ({...x, name: intl.formatMessage({ id: x.name })}))}
                        name={Lx.General.STATUS}
                        selected={filterState.draft.selectedStatuses}
                        maxWidth={300}
                        setSelectedValues={(values) => dispatch({ action: 'STATUSES_UPDATE', payload: values })}
                        intl={intl}/>
                }
            </Box>
            <Box className={styles.filterFooter}>
                <ResetLink onClick={resetFilter}><FormattedMessage id={Lx.General.RESET_FILTER} /></ResetLink>
                <Button variant={'scheduleAction'} onClick={applyFilter}><FormattedMessage id={Lx.General.APPLY_FILTER} /></Button>
            </Box>
        </Popover>
    </>)
}