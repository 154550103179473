import React, {useEffect, useRef} from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import GridDatePicker from '../date-field';
import moment from 'moment';

type Moment = moment.Moment;

interface Props {
    value: string;
    onChange: (value?: string) => void;
    active: boolean;
}

const DateEditCell = (props: Props ) => {
    const { onChange, active } = props;
    const [value, setValue] = React.useState<Moment | null>(moment(props.value));
    const ref = useRef<HTMLDivElement>(null);

    const handleChange = (value: Moment | null) => {
        setValue(value);
        onChange(value?.format('yyyy-MM-DD') ?? '');
    }

    useEffect(() => {
        if (!active) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref.current?.blur();
        }
    }, [active]);

    return (<LocalizationProvider dateAdapter={AdapterMoment}>
        <GridDatePicker value={value} onChange={handleChange} ref={ref} />
    </LocalizationProvider>)
}

export default DateEditCell;