import {GridColumnProps, GridRowModel, ValidRowModel} from './types';
import {IntlShape} from 'react-intl';
import React, {useState} from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import classNames from 'classnames';
import styles from './grid.module.scss';
import GridRowCell from './grid-cell';

interface Props {
    index: number;
    columns: GridColumnProps[];
    row: Readonly<ValidRowModel>;
    intl: IntlShape;
    lazy: boolean;
    noHeader: boolean;
    onRowEditStop?: (row: GridRowModel, state: GridRowModel) => void;
}

const GridRow = (props: Props) => {
    const { row, intl, noHeader } = props;
    const columns = props.columns.filter((x) => x.visible === undefined  || x.visible);
    const [editState, setEditState] = useState<GridRowModel | null>(null);
    const [rowEditing, setRowEditing] = useState<boolean>(false);

    const handleBlur = (reason?: string) => {
        if (reason && reason !== 'Escape') {
            return;
        }
        if (rowEditing) {
            setRowEditing(false)
            if (reason === 'Escape') {
                // leave without save
                setEditState(null);
                return;
            }
            if (editState !== null) {
                props.onRowEditStop?.(row, editState);
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCellEditStart = (field: string, value: any) => {
        if (!rowEditing) {
            setRowEditing(true)
        }
    }

    const handleCellEdit = (field: string, value: any) => {
        setEditState((state) => {
            if (state == null) {
                return {
                    id: 0,
                    [field]: value,
                }
            }

            return {
                ...state,
                [field]: value
            }
        })
    }

    const cells: React.ReactNode[] = [];
    let cellIndex = 0;
    for (let index = 0; index < columns.length; index++) {
        const column = columns[index];
        let span = column.colSpan ? column.colSpan(row): null;
        if (span !== null && index + span >= columns.length - 1) {
            span = null;
        }
        cells.push(<GridRowCell key={cellIndex}
            field={column.field}
            row={row}
            column={column}
            intl={intl}
            rowEdit={rowEditing}
            onCellEditStart={handleCellEditStart}
            onCellEditStop={handleCellEdit}
            editState={editState}
            lazyDataLoad={props.lazy}
            displayPlaceholder={noHeader}
            gridColumn={{ s: index + 1, e: span ? index + span + 1 : index + 1 }}/>)

        if (span) {
            index += span - 1;
        }

        cellIndex++;
    }

    return (<ClickAwayListener onClickAway={() => handleBlur()}>
        {
            row && <div id={`data-row-${row.id}`} aria-rowindex={props.index} onKeyDown={(event) => handleBlur(event.key)} className={classNames({
                [styles.row]: true
            })}>
                <div className={classNames({
                    [styles.rowContainer]: true,
                    [styles.active]: rowEditing
                })}>
                    { cells }
                </div>
            </div>
        }
    </ClickAwayListener>)
}

export default React.memo(GridRow);