import {GridIdNameModel, GridRowCellProps} from './types';
import React, {useEffect, useState} from 'react';
import styles from './grid.module.scss';
import EditCell from './components/edit-cell';
import Cell from './components/cell';

const GridRowCell = (props: GridRowCellProps) => {
    const { column, row, field, rowEdit, editState, onCellEditStart, onCellEditStop } = props;
    const [editable] = useState<boolean>(() => {
        const editable = column.editable;
        if (!editable) {
            return false
        }
        if (typeof editable === 'function') {
            return editable(row)
        }

        return editable.valueOf()
    });
    const [options, setOptions] = useState<GridIdNameModel[]>(() => {
        const selectableOptions = column.selectableOptions;
        if (!selectableOptions){
            return []
        }

        if (typeof selectableOptions === 'function') {
            return []
        }

        return selectableOptions
    });
    const [edit, setEdit] = useState<boolean>(false)
    const value = column.value ? column.value(row) : row[field];
    const [displayValue, setDisplayValue] = useState<any>(column.displayValue ? column.displayValue(value, row, props.intl) : row[field])
    const placeholder = props.displayPlaceholder ?
        column.type === 'singleSelect'
            ? displayValue
            : column.localizationKey !== undefined ? props.intl.formatMessage({id: column.localizationKey})
                : undefined
        : undefined;

    const handleContainerLeave = () => {
        if (!edit) {
            return
        }

        setEdit(false)
    }

    const handleContainerClick = () => {
        if (!editable) {
            return
        }

        setEdit(true)
        onCellEditStart?.(field, value);
    }

    const handleEditCellChange = (value: any) => {
        if (value === undefined) {
            return;
        }

        setDisplayValue(edit ?
            column.displayValue ?
                column.displayValue(value, row, props.intl)
                : value
            : column.displayValue ?
                column.displayValue(value, row, props.intl)
                : row[field])

        if (!edit) {
            return;
        }

        onCellEditStop?.(field, value)
    }

    useEffect(() => {
        if (column.selectableOptions && typeof column.selectableOptions === 'function') {
            if (!props.lazyDataLoad) {
                column.selectableOptions(row).then((r) => setOptions(r))
            }
        }
    }, [row]);

    useEffect(() => {
        if (editState === null) {
            setDisplayValue(column.displayValue ?
                column.displayValue(column.value ? column.value(row) : row[field], row, props.intl)
                : row[field])
            setEdit(false);
        }
    }, [editState]);

    useEffect(() => {
        if (edit && !rowEdit) {
            setEdit(false);
        }
    }, [rowEdit]);

    return (<>
        {
            column.cellRender ? <div className={styles.baseCell} 
                style={{
                    gridColumn: `${props.gridColumn.s} / ${props.gridColumn.e}`
                }}>{!edit ? column!.cellRender(field, row, props.intl) : column.editRender ? column.editRender(field, row, value, handleEditCellChange)
                    : <EditCell type={column.type ?? 'string'}
                        value={column.value ? column.value(row) : row[field]}
                        onChange={handleEditCellChange}
                        active={edit}
                        placeholder={placeholder}
                        options={props.lazyDataLoad ? [] : options }
                        lazyLoad={props.lazyDataLoad}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        getOptions={ props.lazyDataLoad && column.selectableOptions !== undefined && typeof column.selectableOptions === 'function' ? () => column.selectableOptions(row) : undefined } />}
            </div>
                : <Cell onOutsideClick={handleContainerLeave}
                    cellProps={{
                        onClick: () => handleContainerClick(),
                        style: {
                            gridColumn: `${props.gridColumn.s} / ${props.gridColumn.e}`
                        }
                    }}
                    flex={column.flex ?? false} muted={column.muted ?? false} editable={editable}
                    type={column.type ?? 'string'}
                    placeholder={placeholder}
                    active={edit}>
                    {edit ? <EditCell type={column.type ?? 'string'}
                        value={column.value ? column.value(row) : row[field]}
                        onChange={handleEditCellChange}
                        options={options}
                        active={edit}
                        placeholder={placeholder}
                        lazyLoad={props.lazyDataLoad}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        getOptions={ props.lazyDataLoad && column.selectableOptions !== undefined && typeof column.selectableOptions === 'function' ? () => column.selectableOptions(row) : undefined }/>
                        : displayValue
                    }
                </Cell>
        }
    </>)
}

export default GridRowCell;