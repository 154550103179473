import React, {Fragment, ReactNode, RefAttributes, useEffect, useState} from 'react';
import {RowCollapse, State} from './components/row-collapse';
import {
    GridColumnProps,
    GridFlexProps, GridGroupByProps, GridRef, GridProps,
    GridRowModel
} from './types';
import styles from './grid.module.scss'
import classNames from 'classnames';
import {Button, LinearProgress} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import {Lx} from '../../../i18n/consts';

import _ from 'lodash';
import GridRow from './grid-row';

const TimiDataGrid = (props: GridProps, ref: React.Ref<GridRef>) => {
    const {columns, groupBy, intl: externalIntl, loading} = props;
    const [init, setInit] = useState(false);
    const [collapseRows, setCollapseRows] = useState<Array<State>>([])
    const [isAnyRowExpand, setIsAnyRowExpand] = useState(false)
    const intl = externalIntl
    const lazyDataLoad = props.editDataLoadingMode !== undefined && props.editDataLoadingMode === 'server';
    const gridRef = React.useRef<HTMLDivElement>(null);
    const rows = React.useMemo(() => props.rows, [props.rows]);

    React.useImperativeHandle(ref, () => ({
        element: gridRef.current,
        collapseRows: refHandleExpand
    }), [init, collapseRows, rows, isAnyRowExpand]);

    const refHandleExpand = () => {
        if (init) {
            handleExpand();
        }
    }

    const handleRowStateChange = (state: State) => {
        const index = collapseRows.findIndex((s) => s.rowKey === state.rowKey);
        if (state.open) {
            if (!isAnyRowExpand) {
                setIsAnyRowExpand(true)
            }
        } else {
            if (collapseRows.filter((x) => x.open).length < 2) {
                setIsAnyRowExpand(false);
            }
        }
        if (index > -1) {
            setCollapseRows((s) => {
                s[index] = state
                return s;
            })
            return
        }

        setCollapseRows((s) => {
            const ns = s.slice();
            ns.push(state);
            return ns;
        })
    };

    const handleExpand = () => {
        if (!isAnyRowExpand) {
            setCollapseRows((s) => {
                s.filter((x) => x.isAbleToOpen).forEach((s) => s.forceExpand())
                setIsAnyRowExpand(true)
                return s.map((s) => ({...s, open: true}))
            })
            return
        }

        setCollapseRows((s) => {
            s.filter((sx) => sx.open).forEach((sy, index) => {
                sy.forceCollapse()
                s[index].open = false;
            })
            setIsAnyRowExpand(false);
            return s;
        })
    }

    const generateTemplateColumns = (): React.CSSProperties => {
        const values: string[] = [];

        columns.filter((x) => x.visible === undefined || !x.visible).forEach((column: GridColumnProps) => {
            const width = column.width ? `${column.width}px` : '150px';
            const flexed = column.flex !== undefined ? typeof column.flex === 'boolean' ? '1fr'
                : `${(column.flex as GridFlexProps).grow}fr` : null;

            if (flexed !== null) {
                values.push(flexed);
            } else {
                values.push(width);
            }
        })

        return { gridTemplateColumns: `${values.join(' ')}` };
    }

    const recursivelyGenerateGroupRows = (groupByProps: GridGroupByProps, groupFields: string[], rows: GridRowModel[], startIndex: number): ReactNode[] => {
        if (startIndex > 0 && groupFields.length < startIndex + 1) {
            return rows.map((r, index) => {
                return (<GridRow key={index} index={index} columns={columns} row={r} intl={intl}
                    onRowEditStop={props.onRowEditEnd} lazy={lazyDataLoad} noHeader={props.hideHeader ?? false} />)
            })
        }

        const field = groupFields[startIndex];
        return _.chain(rows).groupBy(field).map((value, key) => {
            if (key === '') {
                return (<Fragment key={'empty-key'} />);
            }
            return (value.length > 1 || startIndex < 1
                ? startIndex < 1 
                    ? <RowCollapse nested={startIndex} key={`collapse-${key}`} rowKey={key}
                        rowHeader={groupByProps.groupByRenderer(field, key, value, intl, startIndex + 1)}
                        onStateChange={(startIndex < 1 ? handleRowStateChange : () => void (0))}
                        contentProps={{
                            style: generateTemplateColumns()
                        }} showButton={groupByProps.isGroupHasValues ? groupByProps.isGroupHasValues(value) : true}>
                        {recursivelyGenerateGroupRows(groupByProps, groupFields, value, startIndex + 1)}
                    </RowCollapse> 
                    : <Fragment key={key}>
                        {recursivelyGenerateGroupRows(groupByProps, groupFields, value, startIndex + 1)}
                        {
                            value.length <= startIndex + 1 && <div id={`separator-${field}`} key={`separator-${field}`}
                                className={styles.row + ' ' + styles.separator}></div>
                        }
                    </Fragment>
                : <Fragment key={key}>
                    <GridRow key={value[0].id} index={value[0].id} columns={columns} row={value[0]} intl={intl}
                        onRowEditStop={props.onRowEditEnd} lazy={lazyDataLoad} noHeader={props.hideHeader ?? false}/>
                    <div id={`separator-${field}`} key={`separator-${field}`}
                        className={styles.row + ' ' + styles.separator}></div>
                </Fragment>)
        }).value()
    }

    useEffect(() => {
        props.onAnyRowExpand?.(isAnyRowExpand);
    }, [isAnyRowExpand]);

    useEffect(() => {
        if (!init) {
            setInit(true);
        }
    }, [])

    return (<div className={styles.container} ref={gridRef}>
        {
            loading ? <LinearProgress hidden={!loading} sx={{height: 2}}/> : <div className={styles.topBorder}/>
        }
        {
            props.hideHeader || !props.hideHeader && <div className={styles.header}>
                <Button className={classNames({
                    [styles.collapseButton]: true,
                    [styles.expand]: isAnyRowExpand
                })} onClick={() => handleExpand()}><span><ChevronRightIcon /></span><span className={styles.text}>{
                        isAnyRowExpand ? intl.formatMessage({ id: Lx.General.COLLAPSE_ALL }) : intl.formatMessage({ id: Lx.General.EXPAND_ALL })
                    }</span></Button>
                {
                    columns.filter((c) => !c.noHeader && (c.visible === undefined || c.visible)).map((c, index) => (
                        <div key={`column_${index}`} className={classNames({
                            [styles.cell]: true,
                            [styles.flex]: c.flex ? c.flex : false
                        })} style={{ width: c.width ? c.width : 'auto' }}>{c.localizationKey ? intl.formatMessage({ id: c.localizationKey }) : c.headerName}</div>))
                }
            </div>
        }
        <div className={styles.body}>
            {
                !groupBy ? rows.map((r, index) => (
                    <GridRow index={index} key={index} columns={columns} row={r} intl={intl} onRowEditStop={props.onRowEditEnd} lazy={lazyDataLoad} noHeader={props.hideHeader ?? false} />))
                    : recursivelyGenerateGroupRows(groupBy, groupBy.rowGroupingModel, rows, 0)
            }
        </div>
    </div>)
}

export default React.forwardRef(TimiDataGrid) as (props: GridProps & RefAttributes<GridRef>) => React.JSX.Element;