import {VisibleDays} from './types';
import {AxiosInstance, AxiosResponse} from 'axios';

export interface IReportApi {
    downloadByEmployee<T>(dateFrom: string, dateTo: string, hideEmptyProjects?: boolean, employeeIds?: number[], projectIds?: number[]): Promise<AxiosResponse<T>>;
    downloadByProject<T>(dateFrom: string, dateTo: string, hideEmptyProjects?: boolean, employeeIds?: number[], projectIds?: number[]): Promise<AxiosResponse<T>>;
    downloadDetailed<T>(dateFrom: string, dateTo: string, visibleDays?: VisibleDays, employeeIds?: number[], projectIds?: number[]): Promise<AxiosResponse<T>>;
}

export default class ReportApi implements IReportApi {
    private readonly _axiosInstance;
    private readonly _controller = 'report'

    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance;
    }

    async downloadByEmployee<T>(dateFrom: string, dateTo: string, hideEmptyProjects?: boolean | undefined, employeeIds?: number[] | undefined, projectIds?: number[] | undefined): Promise<AxiosResponse<T, any>> {
        return await this.downloadByReport('employee', dateFrom, dateTo, employeeIds, projectIds, hideEmptyProjects)
    }
    async downloadByProject<T>(dateFrom: string, dateTo: string, hideEmptyProjects?: boolean | undefined, employeeIds?: number[] | undefined, projectIds?: number[] | undefined): Promise<AxiosResponse<T, any>> {
        return await this.downloadByReport('project', dateFrom, dateTo, employeeIds, projectIds, hideEmptyProjects)
    }
    async downloadDetailed<T>(dateFrom: string, dateTo: string, visibleDays?: VisibleDays | undefined, employeeIds?: number[] | undefined, projectIds?: number[] | undefined): Promise<AxiosResponse<T, any>> {
        return await this.downloadByReport('detailed', dateFrom, dateTo, employeeIds, projectIds, undefined, visibleDays);
    }

    private async downloadByReport<T>(
        by: 'employee' | 'project' | 'detailed',
        dateFrom: string, 
        dateTo: string,
        employeeIds?: number[] | undefined, 
        projectIds?: number[] | undefined,
        hideEmptyProjects?: boolean | undefined,
        visibleDays?: VisibleDays | undefined,
    ): Promise<AxiosResponse<T>> {
        const employeesString = employeeIds?.map((x) => `&employeeIds=${x}`).join('') ?? '';
        const projectsString = projectIds?.map((x) => `&projectIds=${x}`).join('') ?? '';
        const hideEmptyProjectsString = hideEmptyProjects !== undefined ? hideEmptyProjects ? '&hideEmptyProjects=true' : '&hideEmptyProjects=false' : '';
        const visibleDaysString = visibleDays !== undefined ? `&visibleDays=${visibleDays}` : '';
        return await this._axiosInstance.get<T>(`${this._controller}/${by === 'detailed' ? 'detailed' : `by-${by}`}/download?dateFrom=${dateFrom}&dateTo=${dateTo}${hideEmptyProjectsString}${visibleDaysString}${employeesString}${projectsString}`, {
            responseType: 'blob',
        })

    }
}