import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {FilterState} from '../../../../components/Schedule/header/components/filter/types';
import {Year} from '../../../../features/settings/workcalendar/types';
import moment from 'moment';
import {
    Hours,
    TimeSheetGroupType,
    TimeSheetItem,
    TimeSheetItemStatus,
    TimeSheetItemType
} from '../../../../features/schedule/types';
import {useTimeSheetSearch} from '../../contexts/search';
import {useTimeSheetGroupBy} from '../../contexts/group-by';
import {updateTimesheetDateRangeFilter, updateTimesheetPropertyFilter} from '../../../../features/schedule';
import styles from '../../../../components/Schedule/header/schedule-header.module.scss';
import {Box, Button, IconButton, Skeleton, Tooltip} from '@mui/material';
import ViewSelector from '../../../../components/Schedule/header/components/schedule-type-selector';
import ScheduleDateSelector from '../../../../components/Schedule/header/components/schedule-date-selector';
import {Lx} from '../../../../i18n/consts';
import {SVGS} from '../../../../assets/images';
import ReportModal from '../../../../components/Schedule/modals/report-modal';
import classNames from 'classnames';
import Filter from '../../../../components/Schedule/header/components/filter';
import TotalV2 from '../../../../components/Schedule/total-v2';
import TimeSheetGroupTool from '../../../../components/Schedule/timesheet-group-tool';
import { useAppSelector } from '../../../../hooks/hooks';

interface Props {
    year: Year;
    today: moment.Moment;
    loading: boolean;
    isItemsExpand: boolean;
    items: Array<TimeSheetItem>;
    onCreateTimeSheetItemClick: () => void;
    onCollapseClick: () =>void;
}

const initialHoursState: Hours = {billed: 0, totalHours: 0, approved: 0}

export const TopBar = ({ year, today, items, onCreateTimeSheetItemClick, loading, isItemsExpand, onCollapseClick }: Props) => {
    const intl = useIntl();
    const { change: searchChange, value: search } = useTimeSheetSearch();
    const { change: groupByChange, groupBy } = useTimeSheetGroupBy();
    const dateRangeFilter = useAppSelector((selector) => selector.timesheet.filter.dateRangeFilter);
    const [hours, setHours] = useState<Hours>(initialHoursState);
    const [dateRange, setDateRange] = useState<string[]>([]);
    const [isAnyItemsExpanded, setIsAnyItemsExpanded] = useState(isItemsExpand);

    const ready = loading === undefined || !loading;

    const handleDateRangeChange = (values: string[]) => new Promise<void>(() => {
        updateTimesheetDateRangeFilter({
            startDate: values[0].replaceAll('/', '-'),
            endDate: values[1].replaceAll('/', '-'),
        })
    })

    const handlePropertyFilterChanged = (value: FilterState) => new Promise<void>(() => {
        updateTimesheetPropertyFilter({
            selectedProjects: value.selectedProjects ?? [],
            selectedEmployees: value.selectedEmployees ?? [],
            selectedShiftTypes: value.selectedShiftTypes ?? [],
            selectedAbsenceTypes: value.selectedAbsenceTypes ?? [],
            selectedStatuses: value.selectedStatuses ?? [],
        })
    })

    const handleExpandButtonClick = () => {
        setIsAnyItemsExpanded(!isAnyItemsExpanded);
        onCollapseClick();
    }

    useEffect(() => {
        setIsAnyItemsExpanded(isItemsExpand);
    }, [isItemsExpand]);

    useEffect(() => {
        if (!items) {
            return setHours(initialHoursState);
        }

        setHours({
            totalHours: items.map((d) => d.hours).reduce((sum, a) => sum + a, 0),
            approved: items.filter((s) => s.status == TimeSheetItemStatus.APPROVED || s.status == TimeSheetItemStatus.BILLABLE).map((s) => s.hours).reduce((sum, a) => sum + a, 0),
            billed: items.filter((s) => s.status == TimeSheetItemStatus.BILLABLE).map((s) => s.hours).reduce((sum, a) => sum + a, 0),
            workHours: groupBy === TimeSheetGroupType.EMPLOYEEVIEW ? items.filter((s) => s.timeshiftType === TimeSheetItemType.SHIFT).reduce((sum, a) => sum + a.hours, 0) : undefined,
            absenceHours: groupBy === TimeSheetGroupType.EMPLOYEEVIEW ? items.filter((s) => s.timeshiftType === TimeSheetItemType.ABSENCE).reduce((sum, a) => sum + a.hours, 0) : undefined,

        })
    }, [items]);

    useEffect(() => {
        setDateRange([
            dateRangeFilter.startDate.replaceAll('-', '/'),
            dateRangeFilter.endDate.replaceAll('-', '/')
        ]);
    }, [dateRangeFilter])

    //initial
    useEffect(() => {
        handleDateRangeChange([
            today.clone().add(-1, 'week').format('yyyy/MM/DD'),
            today.format('yyyy/MM/DD')
        ]).then();
    }, []);

    return (
        <div className={styles.container}>
            <Box className={styles.header}>
                {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
                <div className={`${styles.headerItem} ${styles.scheduleSelector}`}>
                    <ViewSelector value={groupBy}
                        onChange={(v) => groupByChange(v ?? TimeSheetGroupType.EMPLOYEEVIEW)}/>
                </div>
                {
                    ready ? <ScheduleDateSelector selectedDates={dateRange} onRangeSelected={handleDateRangeChange} year={{
                        id: year.year, months: year.months.map((m) => ({
                            id: m.month, days: m.days.map((d) => ({
                                id: d.day,
                                type: d.isShort ? 'short' : d.isHoliday ? 'off' : 'default'
                            }))
                        }))
                    }}/> : <Skeleton width={500} />
                }
                <Box gap={1} display={'flex'} height={'100%'} className={styles.headerItem}>
                    { ready ? <Tooltip title={intl.formatMessage({ id: Lx.Schedule.CREATE_NEW_TIMESHIFT_SHIFT })}>
                        <Button className={styles.actionButton} variant={'scheduleAction'} sx={{ height: '100%' }} onClick={onCreateTimeSheetItemClick}>
                            <SVGS.PlusIcon className={styles.icon} />
                        </Button>
                    </Tooltip> : <Skeleton width={50}></Skeleton>}
                    { ready ? <ReportModal today={today} year={year} /> : <Skeleton width={50} />}
                </Box>
            </Box>
            <Box className={styles.subheader}>
                <div style={{ display: 'flex', gap: '8px', flexDirection: 'row' }}>
                    {
                        ready && <Tooltip
                            title={isAnyItemsExpanded ? intl.formatMessage({id: Lx.General.COLLAPSE_ALL}) : intl.formatMessage({id: Lx.General.EXPAND_ALL})}>
                            <IconButton onClick={() => handleExpandButtonClick()} className={classNames({
                                [styles.collapseButton]: true,
                                [styles.active]: isAnyItemsExpanded
                            })}>
                                <SVGS.RightChevronIcon className={styles.icon}/>
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        ready ? <input id={'search-bar-input'} className={styles.input} value={search}
                            onChange={(event) => searchChange(event.target.value)}
                            placeholder={'Search'} size={30}/>
                            : <Skeleton width={212}></Skeleton>
                    }
                    { ready ? <Filter onChange={handlePropertyFilterChanged} />
                        : <Skeleton width={115}></Skeleton>
                    }
                </div>
                <div className={styles.info}>
                    {
                        hours && <>
                            <TotalV2 totalHours={hours.totalHours}
                                approved={hours.approved}
                                billed={hours.billed}
                                absenceHours={hours.absenceHours}
                                workHours={hours.workHours}
                                hideTrackingHours={groupBy === TimeSheetGroupType.PROJECTVIEW || hours.workHours === undefined || hours.workHours < 1}/>
                            <TimeSheetGroupTool intl={intl} totalHours={hours.totalHours} approved={0} billed={0} approvable={0} billable={0} hideButtons />
                        </>
                    }
                </div>
            </Box>
        </div>
    )
}