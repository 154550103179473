import React from 'react'
import DataRowHeader from '../../../../../../components/Schedule/data/row-header';
import {
    TimeSheetGroupType,
    TimeSheetItem,
    TimeSheetItemStatus,
    TimeSheetItemType
} from '../../../../../../features/schedule/types';
import {GridGroupByProps} from '../../../../../../components/shared/grid/types';

const getTotalCandidatesToApproveAll = (viewItems: TimeSheetItem[]): number => {
    return viewItems.filter((vi) => vi.status != TimeSheetItemStatus.APPROVED && vi.status != TimeSheetItemStatus.BILLABLE).map((vi) => vi.hours).reduce((sum, a) => sum + a, 0);
}

const getTotalCandidatesToBillApp = (viewItems: TimeSheetItem[]): number => {
    return viewItems.filter((vi) => vi.timeshiftType === TimeSheetItemType.SHIFT && vi.status != TimeSheetItemStatus.BILLABLE).map((vi) => vi.hours).reduce((sum, a) => sum + a, 0);
}

const getTotal = (items: TimeSheetItem[]) => {
    return {
        total: items.map((s) => s.hours).reduce((sum, a) => sum + a, 0),
        approved: items.filter((s) => s.status == TimeSheetItemStatus.APPROVED || s.status == TimeSheetItemStatus.BILLABLE).map((s) => s.hours).reduce((sum, a) => sum + a, 0),
        billed: items.filter((s) => s.status == TimeSheetItemStatus.BILLABLE).map((s) => s.hours).reduce((sum, a) => sum + a, 0),
        workHours: items.filter((i) => i.timeshiftType === TimeSheetItemType.SHIFT).map((i) => i.hours).reduce((sum, a) => sum + a, 0),
        absenceHours: items.filter((i) => i.timeshiftType === TimeSheetItemType.ABSENCE).map((i) => i.hours).reduce((sum, a) => sum + a, 0)
    }
}

const useGridGroupBy = (
    groupType: TimeSheetGroupType,
    handleApproveAllClick: (timeshifts: Array<TimeSheetItem>) => Promise<void>,
    handleBillAllClick: (timeshifts: Array<TimeSheetItem>) => Promise<void>,
    handleDisapproveAllClick: (timeshift: Array<TimeSheetItem>) => Promise<void>,
    handleCancelAllBillingClick: (timeshift: Array<TimeSheetItem>) => Promise<void>,
    onAddNewShiftAddClick: (timeshift: TimeSheetItem) => Promise<void>
): GridGroupByProps => React.useMemo(() => ({
    rowGroupingModel: groupType === TimeSheetGroupType.EMPLOYEEVIEW
        ? ['employeeName', 'date']
        : ['targetName', 'employeeName'],
    groupByRenderer: (groupField, groupFieldValue, value, intl, level) => {
        switch (groupField) {
            case  'date':
            case 'targetName':
            case 'employeeName': {
                const timeshifts = value as Array<TimeSheetItem>;
                const total = getTotal(timeshifts);
                const approvable = getTotalCandidatesToApproveAll(timeshifts);
                const billable = getTotalCandidatesToBillApp(timeshifts);
                return (<DataRowHeader name={groupFieldValue}
                    total={total.total}
                    approved={total.approved}
                    billed={total.billed}
                    approvable={approvable}
                    billable={billable}
                    workHours={total.workHours}
                    absenceHours={total.absenceHours}
                    hideTrackingHours={groupField === 'targetName'}
                    onApproveAllClick={async () => await handleApproveAllClick(timeshifts)}
                    onBillAllClick={async () => await handleBillAllClick(timeshifts)}
                    onDisapproveAllClick={async () => await handleDisapproveAllClick(timeshifts)}
                    onCancelAllBillingClick={async () => await handleCancelAllBillingClick(timeshifts)}
                    onAddNewShiftClick={async () => await onAddNewShiftAddClick({...timeshifts[0], date: ''})}
                    hideButtons={level !== undefined && level > 1}
                    intl={intl}/>)
            }

            default:
                return (<></>)
        }
    },
    isGroupHasValues: (values) => {
        return values.find((x) => (x as TimeSheetItem).hours > 0) !== undefined;
    }
}), [groupType, handleApproveAllClick, handleBillAllClick, handleDisapproveAllClick, handleCancelAllBillingClick, onAddNewShiftAddClick])

export default useGridGroupBy;